import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import SearchInput from "./SearchInput";
import { CircleArrowLeft} from "lucide-react"; // Icons importieren

const Navigation = ({ title }) => { // Der Titel wird als Prop übergeben
    const { user } = useAuth();
    
  return (
    <div className="navbar">
      <div className="flex-1">
        <Link to="/search" className="text-xl font-bold text-primary">
        <div style={{ paddingRight: '10px' }}>
      <CircleArrowLeft size={24} />
    </div>
        </Link>
        <h2 className="text-lg font-semibold">{title}</h2>
      </div>
      <div className="flex-none">
        {/* Hier wird die Suchleiste hinzugefügt */}
        <div className="mr-4">
          <SearchInput />
        </div>
        <ul className="menu menu-horizontal px-1">
          {user?.user_is_admin ? (
            <li>
              <Link to="/admin" className="font-medium">
                Admin
              </Link>
            </li>
          ) : (
            <>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navigation;