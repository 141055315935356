import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import SidebarNav from "./components/SidebarNav"; // Sidebar-Komponente
import SearchBarTop from "./components/SearchBarTop"; // Neue SearchBarTop-Komponente
import { useTitle } from "./context/TitleContext"; // Titel-Kontext importieren

const LayoutWithSidebar = () => {
  const location = useLocation();
  const { title } = useTitle(); // Titel aus dem Kontext holen

  // Pfade, auf denen die Sidebar nicht angezeigt werden soll
  const noSidebarPaths = ["/login", "/admin"];
  const hideSidebar = noSidebarPaths.includes(location.pathname);

  // Pfade, auf denen die SearchBarTop nicht angezeigt werden soll
  const noSearchBarPaths = ["/search", "/admin"];
  const showSearchBar = !noSearchBarPaths.includes(location.pathname);

  return (
    <div className="flex h-screen">
      {/* Sidebar wird nur angezeigt, wenn der aktuelle Pfad nicht in der Ausschlussliste ist */}
      {!hideSidebar && <SidebarNav />}

      {/* Hauptinhalt */}
      <div className={`flex-1 overflow-y-auto`}>
        {/* SearchBarTop wird angezeigt, außer auf definierten Seiten */}
        {showSearchBar && <SearchBarTop title={title} />} {/* Dynamischer Titel wird hier übergeben */}

        {/* Hier werden die Inhalte der aktuellen Route gerendert */}
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutWithSidebar;