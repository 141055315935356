import React, { useState } from "react";
import { Form } from "react-bootstrap";
import axiosInstance from "../api/axiosInstance";

const EditableTags = ({ documentId, initialTags, onUpdate }) => {
  const [tags, setTags] = useState(initialTags || []);
  const [newTag, setNewTag] = useState("");

  // Hinzufügen eines neuen Tags
  const addTag = async () => {
    if (!newTag.trim()) return;

    try {
      // Sende den neuen Tag an die API
      await axiosInstance.post(`/tags/${documentId}/add`, { tag: newTag.trim() });

      // Aktualisiere den State und leere das Input-Feld
      const updatedTags = [...tags, newTag.trim()];
      setTags(updatedTags);
      setNewTag("");
      onUpdate(updatedTags); // Parent-Komponente informieren
    } catch (error) {
      console.error("Fehler beim Hinzufügen des Tags:", error);
    }
  };

  // Entfernen eines Tags
  const removeTag = async (tagToRemove) => {
    try {
      // Sende die Löschanfrage an die API
      await axiosInstance.post(`/tags/${documentId}/remove`, { tag: tagToRemove });

      // Entferne den Tag lokal
      const updatedTags = tags.filter((tag) => tag !== tagToRemove);
      setTags(updatedTags);
      onUpdate(updatedTags); // Parent-Komponente informieren
    } catch (error) {
      console.error("Fehler beim Entfernen des Tags:", error);
    }
  };

  return (
    <div>
      <div className="mb-2">
        <p>
          <strong>Tags:</strong>
        </p>

        {/* Bedingte Anzeige: Zeige Nachricht, wenn keine Tags vorhanden sind */}
        {tags.length === 0 || tags.every((tag) => tag.trim() === "") ? (
          <p className="text-muted">Lege hier eigene Tags fest</p> // Nachricht anzeigen
        ) : (
          tags
            .filter((tag) => tag.trim() !== "") // Leere Tags herausfiltern
            .map((tag, index) => (
              <span
                key={index}
                className="badge badge-primary flex items-center searchbadge"
              >
                {tag}
                <span
                  className="ml-2 cursor-pointer"
                  onClick={() => removeTag(tag)}
                  style={{
                    marginLeft: "8px",
                    cursor: "pointer",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  &times;
                </span>
              </span>
            ))
        )}
      </div>

      {/* Eingabefeld und Button zum Hinzufügen */}
      <div className="d-flex">
        <Form.Control
          type="text"
          className="input input-bordered w-full max-w-xs"
          placeholder="Neuen Tag hinzufügen"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
        />
        <button
          className="btn btn-success ms-2"
          onClick={addTag}
          style={{
            whiteSpace: "nowrap",
            padding: "0.375rem 0.75rem",
            fontSize: "1rem",
          }}
        >
          Hinzufügen
        </button>
      </div>
    </div>
  );
};

export default EditableTags;