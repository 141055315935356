import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Importiere den AuthContext
import axiosInstance from "../api/axiosInstance";
import { useTheme } from "../context/ThemeContext"; // Importiere ThemeContext

const SearchPage = () => {
    const { user, isAuthenticated } = useAuth(); // Benutzer- und Authentifizierungsstatus abrufen
    const { theme } = useTheme(); // Zugriff auf das Theme aus ThemeContext
    const [fetchedUser, setFetchedUser] = useState(null); // Lokal gespeicherte Benutzerdaten
    const [tags, setTags] = useState([]); // Liste der Suchtags
    const [inputValue, setInputValue] = useState(""); // Aktueller Eingabewert
    const [noResults, setNoResults] = useState(false); // Kein Ergebnis gefunden
    const [isSearching, setIsSearching] = useState(false); // Ladezustand
    const [loadingUser, setLoadingUser] = useState(true); // Ladezustand für Benutzer
    const navigate = useNavigate();
    const location = useLocation();

    // Benutzerinformationen laden (wie in der SettingsPage)
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axiosInstance.get("/auth/me"); // API-Aufruf
                setFetchedUser(response.data); // Benutzer lokal speichern
            } catch (error) {
                console.error("Fehler beim Laden der Benutzerdaten:", error);
            } finally {
                setLoadingUser(false); // Ladezustand beenden
            }
        };

        if (!user && isAuthenticated) {
            fetchUserData(); // Benutzer aus der API laden, wenn nicht im AuthContext
        } else {
            setFetchedUser(user); // Benutzer aus AuthContext verwenden
            setLoadingUser(false);
        }
    }, [user, isAuthenticated]);

    // Initialisiere Tags aus location.state, wenn verfügbar
    useEffect(() => {
        if (location.state?.tags) {
            setTags(location.state.tags);
        }
    }, [location.state]);

    const handleSearch = async () => {
        let updatedTags = [...tags];
        if (inputValue.trim() !== "") {
            const newTags = inputValue.trim().toLowerCase().split(" ");
            updatedTags = [...tags, ...newTags];
            setTags(updatedTags);
            setInputValue(""); // Eingabefeld leeren
        }

        setIsSearching(true); // Ladezustand aktivieren
        setNoResults(false); // Kein Ergebnis zurücksetzen

        try {
            const response = await axiosInstance.post("/search", { tags: updatedTags });

            if (response.data.length === 0) {
                setNoResults(true); // Kein Ergebnis gefunden
                return; // Keine Weiterleitung
            }

            // Weiterleitung zur ResultsPage nur bei Ergebnissen
            navigate("/results", { state: { results: response.data, tags: updatedTags } });
        } catch (error) {
            console.error("Fehler bei der Suche:", error);
            alert("Es ist ein Fehler bei der Suche aufgetreten. Bitte versuchen Sie es erneut.");
        } finally {
            setIsSearching(false); // Ladezustand deaktivieren
        }
    };

    const addTagOrSearch = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Verhindert das Standardverhalten der Eingabetaste

            if (inputValue.trim() === "") {
                handleSearch(); // Wenn das Eingabefeld leer ist, starte die Suche
            } else {
                const newTags = inputValue
                    .trim()
                    .toLowerCase()
                    .split(" ")
                    .filter((tag) => tag !== ""); // Keine leeren Tags zulassen
                setTags([...tags, ...newTags]);
                setInputValue(""); // Eingabefeld leeren
            }
        }
    };

    const removeTag = (tagToRemove) => {
        setTags(tags.filter((tag) => tag !== tagToRemove));
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-base-100">
            {/* Animierte Kreise im Hintergrund */}
            <div
                className="absolute"
                style={{
                    width: "100%",
                    height: "100%",
                    left: "0",
                    right: "0",
                    zIndex: "0",
                    overflow: "hidden",
                }}
            >
                <div className="absolute w-[800px] h-[800px] bg-slate-400 rounded-full opacity-30 blur-xl animate-move-circle-1"></div>
                <div
                    className="absolute w-[600px] h-[600px] bg-slate-400 rounded-full opacity-30 blur-xl animate-move-circle-2"
                    style={{
                        right: "0",
                    }}
                ></div>
            </div>
            {loadingUser ? (
                <div>Lade Benutzerdaten...</div>
            ) : (
                <div className="text-center" style={{ zIndex: 2 }}>
                    {/* Logo */}
                    <div className="mb-6">
                        <img
                            src={theme === "dark" ? "/images/anilogo-b.svg" : "/images/anilogo.svg"}
                            alt="Tidion Logo"
                            className="w-48 mx-auto mb-2"
                        />
                    </div>

                    {/* Such-Container */}
                    <div
                        className="w-[95%] sm:w-[90%] lg:w-[890px] p-4 mx-auto"
                        style={{ zIndex: "2" }}
                    >
                        <div
                            className="flex items-center gap-4 p-4 bg-base-100 shadow rounded-full"
                            style={{ border: "1px solid #ddd" }}
                        >
                            {/* Tags */}
                            <div className="flex flex-wrap items-center gap-2 flex-grow">
                                {tags.map((tag, index) => (
                                    <div
                                        key={index}
                                        className="badge badge-primary flex items-center searchbadge"
                                        style={{ borderRadius: "50px" }}
                                    >
                                        {tag}
                                        <span
                                            className="ml-2 cursor-pointer"
                                            onClick={() => removeTag(tag)}
                                        >
                                            &times;
                                        </span>
                                    </div>
                                ))}
                                {/* Eingabefeld */}
                                <input
                                    id="tagInput"
                                    type="text"
                                    placeholder={
                                        tags.length === 0
                                            ? `Hey ${fetchedUser?.user_first_name || "Benutzer"}, Beginne hier deine Suche.`
                                            : "Weitere Begriffe hinzufügen"
                                    }
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={addTagOrSearch}
                                    className="flex-grow input input-bordered bg-base-100 text-base-content focus:outline-none"
                                    style={{
                                        border: "none",
                                        fontSize: "1.125rem", // Textgröße
                                        lineHeight: "1.75rem", // Zeilenhöhe
                                    }}
                                />
                            </div>
                            {/* Suchen-Button */}
                            <button
                                className={`btn btn-primary rounded-full px-8 py-3 text-white ${
                                    isSearching ? "loading" : ""
                                }`}
                                onClick={handleSearch}
                                style={{
                                    whiteSpace: "nowrap", // Verhindert Zeilenumbrüche
                                }}
                                disabled={isSearching} // Deaktiviert während der Suche
                            >
                                Suchen
                            </button>
                        </div>
                    </div>

                    {/* Hinweis bei keinen Ergebnissen */}
                    {noResults && (
                        <div className="alert alert-warning mt-4">
                            Es wurden keine Dokumente gefunden. Versuche andere Suchbegriffe.
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchPage;