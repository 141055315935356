import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../api/axiosInstance";

const SearchInput = ({ initialTags = [] }) => {
    const [tags, setTags] = useState(initialTags);
    const [inputValue, setInputValue] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    // Initialisiere Tags aus location.state, wenn verfügbar
    useEffect(() => {
        if (location.state?.tags) {
            setTags(location.state.tags);
        }
    }, [location.state]);

    const handleSearch = async () => {
        let updatedTags = [...tags];
        if (inputValue.trim() !== "") {
            const newTags = inputValue.trim().toLowerCase().split(" ");
            updatedTags = [...tags, ...newTags];
            setTags(updatedTags);
            setInputValue("");
        }

        try {
            console.log("🔍 Sende Suchanfrage mit Tags:", updatedTags);
            const response = await axiosInstance.post("/search", { tags: updatedTags });
            console.log("📄 API-Antwort:", response.data);
            navigate("/results", { state: { results: response.data, tags: updatedTags } });
        } catch (error) {
            console.error("Fehler bei der Suche:", error);
            alert("Es ist ein Fehler bei der Suche aufgetreten. Bitte versuchen Sie es erneut.");
        }
    };

    const addTagOrSearch = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();

            if (inputValue.trim() === "") {
                handleSearch();
            } else {
                const newTags = inputValue
                    .trim()
                    .toLowerCase()
                    .split(" ")
                    .filter((tag) => tag !== "");
                setTags([...tags, ...newTags]);
                setInputValue("");
            }
        }
    };

    const removeTag = (tagToRemove) => {
        setTags(tags.filter((tag) => tag !== tagToRemove));
    };

    // Dynamischer Platzhalter basierend auf der Seite
    const placeholder =
        location.pathname === "/search"
            ? "Hey! Beginne hier deine Suche."
            : "Suchbegriff";

    return (
        <div className="flex items-center gap-4 p-4 bg-white rounded-full" style={{ border: "1px solid #ddd" }}>
            <div className="flex flex-wrap items-center gap-2 flex-grow">
                {tags.map((tag, index) => (
                    <div
                        key={index}
                        className="badge badge-primary flex items-center searchbadge"
                        style={{ borderRadius: "50px" }}
                    >
                        {tag}
                        <span className="ml-2 cursor-pointer" onClick={() => removeTag(tag)}>
                            &times;
                        </span>
                    </div>
                ))}
                <input
                    id="tagInput"
                    type="text"
                    placeholder={tags.length === 0 ? placeholder : "Weitere Begriffe hinzufügen"}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={addTagOrSearch}
                    className="flex-grow focus:outline-none text-gray-700"
                    style={{
                        border: "none",
                        fontSize: "1.125rem",
                        lineHeight: "1.75rem",
                    }}
                />
            </div>
            <button
                className="btn btn-primary rounded-full px-8 py-3 text-white"
                onClick={handleSearch}
                style={{
                    whiteSpace: "nowrap",
                }}
            >
                Suchen
            </button>
        </div>
    );
};

export default SearchInput;