import React, { useState } from "react";

const DocumentPreviewWithZoom = ({ imageUrl }) => {
    const [isZooming, setIsZooming] = useState(false); // Aktiviert die Lupenansicht
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    // Mausbewegung verfolgen
    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = Math.min(100, Math.max(0, ((e.clientX - left) / width) * 100)); // Begrenzung von 0% bis 100%
        const y = Math.min(100, Math.max(0, ((e.clientY - top) / height) * 100));

        setMousePosition({ x, y });
    };

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "0",
                paddingBottom: "141.4%", // Verhältnis für DIN A4 (1:√2)
                backgroundColor: "#f8f9fa",
                overflow: "hidden",
                cursor: isZooming ? "none" : "pointer", // Versteckt den Cursor im Lupenmodus
            }}
            onMouseMove={handleMouseMove}
            onMouseEnter={() => setIsZooming(true)} // Lupenmodus aktivieren
            onMouseLeave={() => setIsZooming(false)} // Lupenmodus deaktivieren
        >
            {/* Bildvorschau */}
            <img
                src={imageUrl}
                alt="Dokument-Vorschau"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
                draggable={false} // Verhindert, dass das Bild beim Ziehen bewegt wird
            />

            {/* Lupenanzeige */}
            {isZooming && (
                <div
                    style={{
                        position: "absolute",
                        pointerEvents: "none", // Keine Mausinteraktion mit der Lupe
                        top: `${mousePosition.y}%`,
                        left: `${mousePosition.x}%`,
                        transform: "translate(-50%, -50%)", // Zentrierung der Lupe
                        width: "250px", // Größe der Lupe
                        height: "250px",
                        cursor: "none",
                        backgroundColor: "#ffffff",
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: "600%", // Erhöht den Zoom-Faktor auf 4x
                        backgroundPosition: `${mousePosition.x}% ${mousePosition.y}%`,
                        borderRadius: "50%", // Runde Form für die Lupe
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Dezentere Schatten
                        border: "2px solid rgba(255, 255, 255, 0.8)",
                        zIndex: 10,
                    }}
                ></div>
            )}
        </div>
    );
};

export default DocumentPreviewWithZoom;