import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";

const LoginPage = () => {
  const { login, verify2FA, requires2FA, location } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [error, setError] = useState(null);
  const [stage, setStage] = useState("login"); // "login" or "2fa"
  const [showPasswordModal, setShowPasswordModal] = useState(false); // Modal visibility state for password
  const [show2FALostModal, setShow2FALostModal] = useState(false); // Modal visibility state for 2FA lost

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await login(username, password);

      if (response.requires2FA) {
        setStage("2fa");
      }
    } catch (err) {
      setError(err.message || "Ungültige Anmeldedaten.");
    }
  };

  const handle2FAVerify = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await verify2FA(twoFactorCode);
      setStage("login");
    } catch (err) {
      setError(err.message || "Ungültiger 2FA-Code.");
    }
  };

  return (
    <div className="relative flex justify-center items-center min-h-screen bg-gray-900 text-gray-200 overflow-hidden">
      {/* Animated Circles */}
      <div className="absolute w-[800px] h-[800px] bg-gray-700 rounded-full opacity-30 blur-xl animate-move-circle-1"></div>
      <div className="absolute w-[600px] h-[600px] bg-gray-700 rounded-full opacity-30 blur-xl animate-move-circle-2"></div>

      <div className="relative z-10 w-full max-w-md mx-auto p-6 bg-gray-800 rounded-2xl shadow-lg">
        <div className="text-center mb-6">
          <img
            src="/images/anilogo-b.svg" // Dunkles Logo
            alt="Tidion Logo"
            className="mx-auto mb-2 logologin"
          />
          <p className="font-bold text-gray-200">Road2React DEV Tidion</p>
        </div>

        {/* Login Form */}
        {stage === "login" && (
          <form onSubmit={handleLogin} className="space-y-6">
            {error && (
              <div className="alert alert-error bg-red-600 text-gray-100">
                {error}
              </div>
            )}

            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="block py-2.5 w-full text-sm text-gray-200 bg-gray-800 border-0 border-b-2 border-gray-600 appearance-none focus:outline-none focus:ring-0 focus:border-blue-400 peer"
                placeholder="Benutzername" // Placeholder wieder hinzugefügt
                required
              />
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block py-2.5 w-full text-sm text-gray-200 bg-gray-800 border-0 border-b-2 border-gray-600 appearance-none focus:outline-none focus:ring-0 focus:border-blue-400 peer"
                placeholder="Passwort" // Placeholder wieder hinzugefügt
                required
              />
            </div>

            <div className="text-center">
              <a
                href="#!"
                onClick={() => setShowPasswordModal(true)}
                className="text-blue-400 underline"
              >
                Passwort vergessen?
              </a>
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block text-white"
            >
              Login
            </button>
          </form>
        )}

        {/* 2FA Form */}
        {stage === "2fa" && (
          <form onSubmit={handle2FAVerify} className="space-y-6">
            {error && (
              <div className="alert alert-error bg-red-600 text-gray-100">
                {error}
              </div>
            )}

            <div className="alert alert-info bg-black text-gray-100">
              Bitte geben Sie den 2FA-Code ein, der in Ihrer Authenticator-App angezeigt wird.
              <br />
              <strong>Ort: {location || "Kein Ort angegeben"}</strong>
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                id="twoFactorCode"
                value={twoFactorCode}
                onChange={(e) => setTwoFactorCode(e.target.value)}
                className="block py-2.5 w-full text-sm text-gray-200 bg-gray-800 border-0 border-b-2 border-gray-600 appearance-none focus:outline-none focus:ring-0 focus:border-blue-400 peer"
                placeholder="2FA-Code" // Placeholder wieder hinzugefügt
                required
              />
            </div>
            <div className="text-center">
              <a
                href="#!"
                onClick={() => setShow2FALostModal(true)}
                className="text-blue-400 underline"
              >
                2FA Zugang verloren?
              </a>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block text-white bg-blue-500 hover:bg-blue-600"
            >
              Verifizieren
            </button>
          </form>
        )}

        {/* Passwort vergessen Modal */}
        {showPasswordModal && (
          <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-black bg-opacity-75">
            <div className="bg-gray-800 rounded-lg p-6 max-w-md w-full text-gray-200">
              <h3 className="font-bold text-lg">Passwort zurücksetzen</h3>
              <p className="py-4">
                Bitte kontaktieren Sie Ihren Administrator.
                <br />
                Dieser kann Ihr Passwort zurücksetzen.
              </p>
              <div className="flex justify-end mt-4">
                <button
                  className="btn bg-gray-700 text-white hover:bg-gray-600"
                  onClick={() => setShowPasswordModal(false)}
                >
                  Schließen
                </button>
              </div>
            </div>
          </div>
        )}

        {/* 2FA Zugang verloren Modal */}
        {show2FALostModal && (
          <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-black bg-opacity-75">
            <div className="bg-gray-800 rounded-lg p-6 max-w-md w-full text-gray-200">
              <h3 className="font-bold text-lg">2FA Zugang verloren</h3>
              <p className="py-4">
                Bitte kontaktieren Sie Ihren Administrator.
                <br />
                Dieser kann Ihren 2FA-Zugang zurücksetzen.
              </p>
              <div className="flex justify-end">
                <button
                  className="btn bg-gray-700 text-white hover:bg-gray-600"
                  onClick={() => setShow2FALostModal(false)}
                >
                  Schließen
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;