// Datei: src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import axiosInstance from "../api/axiosInstance";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [requires2FA, setRequires2FA] = useState(false);
  const [user, setUser] = useState(null);
  const [tempToken, setTempToken] = useState(null);
  const [location, setLocation] = useState(null); // Speichert die 2FA-Location

  // Benutzerinformationen abrufen
  const fetchUser = async () => {
    try {
      const response = await axiosInstance.get("/auth/me");
      setIsAuthenticated(true);
      setUser(response.data);
    } catch (error) {
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  // Login-Methode
  const login = async (username, password) => {
    try {
      const response = await axiosInstance.post("/auth/login", { username, password });

      // Prüfen, ob 2FA erforderlich ist
      if (response.data.requires2FA) {
        setRequires2FA(true);
        setTempToken(response.data.token); // Temporärer Token für 2FA
        setLocation(response.data.location || "Kein Ort angegeben");
        return { requires2FA: true };
      }

      // Wenn keine 2FA erforderlich ist
      localStorage.setItem("token", response.data.token);
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
      fetchUser();
      return { requires2FA: false };
    } catch (error) {
      console.error("Fehler beim Login:", error.response?.data?.error || error.message);
      throw new Error("Ungültige Anmeldedaten.");
    }
  };

  // 2FA-Code verifizieren
  const verify2FA = async (code) => {
    try {
      const response = await axiosInstance.post(
        "/auth/verify-2fa",
        { token: code },
        { headers: { Authorization: `Bearer ${tempToken}` } }
      );

      localStorage.setItem("token", response.data.token);
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
      setRequires2FA(false);
      setTempToken(null);
      fetchUser();
    } catch (error) {
      console.error("Fehler bei der 2FA-Verifizierung:", error.message);
      throw new Error("Ungültiger 2FA-Code.");
    }
  };

  // Logout-Methode
  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setUser(null);
  };

  // Benutzerdaten beim Laden der Seite abrufen
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        requires2FA,
        location,
        login,
        verify2FA,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);