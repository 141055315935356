// Datei: src/pages/2FAVerificationPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";

const TwoFAVerificationPage = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);

  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      await axiosInstance.post("/auth/verify-2fa", { token: code });
      navigate("/search");
    } catch (error) {
      setError("Ungültiger Code. Bitte versuchen Sie es erneut.");
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      <Row className="w-100">
        <Col md={6} className="mx-auto">
          <div className="border p-4 rounded shadow-sm bg-white">
            <h2 className="text-center mb-4">Zwei-Faktor-Authentifizierung</h2>
            <Form onSubmit={handleVerify}>
              {error && <Alert variant="danger">{error}</Alert>}

              <Form.Group controlId="2faCode" className="mb-3">
                <Form.Label>2FA-Code</Form.Label>
                <Form.Control
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Geben Sie Ihren 2FA-Code ein"
                  required
                />
              </Form.Group>

              <div className="d-grid">
                <Button type="submit" variant="primary" size="lg">
                  Verifizieren
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TwoFAVerificationPage;