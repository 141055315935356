import React from "react";
import ReactDOM from "react-dom/client"; // Ändere das von "react-dom" zu "react-dom/client"
import App from "./App";
import { AuthProvider } from "./context/AuthContext"; // Importiere den AuthProvider
import './App.css';
import './index.css';
import { useTheme } from "./context/ThemeContext"; // Importiere ThemeContext


// Wähle das Root-Element aus dem HTML-Dokument aus
const rootElement = document.getElementById("root");

// Erstelle einen React-Root mit `createRoot`
const root = ReactDOM.createRoot(rootElement);

// Render die App innerhalb des AuthProvider
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);