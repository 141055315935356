import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import TwoFAVerificationPage from "./pages/2FAVerificationPage";
import AdminPage from "./pages/AdminPage";
import SearchPage from "./pages/SearchPage";
import ResultsPage from "./pages/ResultsPage";
import ProtectedRoute from "./components/ProtectedRoute";
import ChangePassword from "./components/ChangePassword";
import HomePage from "./pages/HomePage";
import SettingsPage from "./pages/SettingsPage";
import LayoutWithSidebar from "./LayoutWithSidebar"; // Layout mit Sidebar
import { useAuth } from "./context/AuthContext";
import { TitleProvider } from "./context/TitleContext"; // Titel-Kontext
import { ThemeProvider } from "./context/ThemeContext"; // Importiere ThemeProvider

const App = () => {
  const { isAuthenticated, user, requires2FA } = useAuth();

  return (
    <Router>
      <ThemeProvider> {/* Theme-Kontext um die gesamte App */}
        <TitleProvider> {/* Titel-Kontext um die gesamte App */}
          <Routes>
            {/* Login-Seite */}
            <Route
              path="/login"
              element={
                !isAuthenticated ? (
                  <LoginPage />
                ) : requires2FA ? (
                  <Navigate to="/2fa" />
                ) : user?.user_is_admin ? (
                  <Navigate to="/admin" />
                ) : (
                  <Navigate to="/search" />
                )
              }
            />

            {/* 2FA-Seite */}
            <Route path="/2fa" element={<TwoFAVerificationPage />} />

            {/* Admin-Seite */}
            <Route
              path="/admin"
              element={
                <ProtectedRoute adminOnly={true}>
                  <AdminPage />
                </ProtectedRoute>
              }
            />

            {/* Layout mit Sidebar */}
            <Route element={<LayoutWithSidebar />}>
              <Route
                path="/search"
                element={
                  <ProtectedRoute>
                    <SearchPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/results"
                element={
                  <ProtectedRoute>
                    <ResultsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/change-password"
                element={
                  <ProtectedRoute>
                    <ChangePassword />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <SettingsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              />
            </Route>

            {/* Fallback für nicht gefundene Seiten */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </TitleProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;