import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../api/axiosInstance";
import PDFViewer from "../components/PDFViewer";
import Comments from "../components/Comments";
import EditableTags from "../components/EditableTags";
import DocumentPreviewWithZoom from "../components/DocumentPreviewWithZoom";
import { useTitle } from "../context/TitleContext";
import LucideIconWrapper from "../components/LucideIconWrapper";




// Entfernt Zahlen nach den Begriffen im TSVECTOR
const cleanTsvector = (tsvector) => {
  if (!tsvector) return "Nicht verfügbar";
  return tsvector
    .replace(/'\w+':\d+/g, (match) => match.split(":")[0]) // Entfernt die Zahlen nach den Begriffen
    .replace(/'/g, ""); // Entfernt die Apostrophe
};

const ResultsPage = () => {
  const location = useLocation();
  const results = location.state?.results || [];
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 8;
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [screenshotData, setScreenshotData] = useState({});
  const [loadingScreenshots, setLoadingScreenshots] = useState({});
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [loadingModalText, setLoadingModalText] = useState("");
  const [viewMode, setViewMode] = useState("card"); // "card" oder "table"

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Suchergebnisse"); // Setze den Titel
  }, [setTitle]);



  // Modal öffnen
  const handleShowModal = (doc) => {
    setSelectedDocument(doc);
    setShowModal(true);
  };

  // Modal schließen
  const handleCloseModal = () => {
    setSelectedDocument(null);
    setShowModal(false);
  };

  // PDF herunterladen
  const downloadPdf = async (documentUuid, event) => {
    event.stopPropagation();
    setIsLoadingPdf(true);
    setLoadingModalText("PDF wird heruntergeladen...");
  
    try {
      const response = await axiosInstance.get(`/files/${documentUuid}/${documentUuid}.pdf`, {
        responseType: "blob",
      });
  
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${documentUuid}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("❌ Fehler beim Herunterladen der PDF-Datei:", error);
      alert("Fehler beim Herunterladen der Datei.");
    } finally {
      setIsLoadingPdf(false);
    }
  };

  // PDF-Vorschau
  const previewPdf = async (documentUuid, event) => {
    event.stopPropagation();
    setIsLoadingPdf(true);
    setLoadingModalText("Vorschau wird generiert...");
  
    try {
      const response = await axiosInstance.get(`/files/${documentUuid}/${documentUuid}.pdf`, {
        responseType: "blob",
      });
  
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
  
      window.open(url, "_blank");
    } catch (error) {
      console.error("❌ Fehler beim Anzeigen der PDF-Datei:", error);
      alert("Fehler beim Anzeigen der Datei.");
    } finally {
      setIsLoadingPdf(false);
    }
  };

  // Screenshot laden
  const fetchScreenshot = async (documentUuid) => {
    if (!documentUuid) {
      console.error("❌ Kein documentUuid übergeben an fetchScreenshot");
      return;
    }
  
    if (screenshotData[documentUuid]) {
      console.log(`📂 Screenshot für ${documentUuid} bereits geladen.`);
      return;
    }
  
    setLoadingScreenshots((prevState) => ({
      ...prevState,
      [documentUuid]: true,
    }));
  
    try {
      console.log(`📤 Abrufen von Screenshot: /files/${documentUuid}/${documentUuid}.png`);
      const response = await axiosInstance.get(
        `/files/${documentUuid}/${documentUuid}.png`,
        { responseType: "arraybuffer" }
      );
  
      const base64Image = `data:image/png;base64,${btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      )}`;
  
      setScreenshotData((prevData) => ({
        ...prevData,
        [documentUuid]: base64Image,
      }));
    } catch (error) {
      console.error(`❌ Fehler beim Abrufen des Screenshots für ${documentUuid}:`, error);
      setScreenshotData((prevData) => ({
        ...prevData,
        [documentUuid]: null,
      }));
    } finally {
      setLoadingScreenshots((prevState) => ({
        ...prevState,
        [documentUuid]: false,
      }));
    }
  };

  // Lade alle Screenshots beim Start
  useEffect(() => {
    results.forEach((doc) => {
      if (doc.document_uuid) { // Korrekt: Nutzung von document_uuid
        console.log(`📂 Screenshot laden für Ordner: ${doc.document_uuid}`);
        fetchScreenshot(doc.document_uuid); // Korrekt: Übergabe von document_uuid
      } else {
        console.error(`❌ Fehler: document_uuid fehlt für Dokument:`, doc);
      }
    });
  }, [results]);

  const sortedResults = [...results].sort(
    (a, b) => new Date(b.document_date) - new Date(a.document_date)
  );

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = sortedResults.slice(
    indexOfFirstResult,
    indexOfLastResult
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (

    <div className="container mx-auto mt-10">
      <div className="flex justify-between items-center mb-4">
        <p>Anzahl der Ergebnisse: {results.length}</p>
        <button
  className="btn btn-outline"
  onClick={() => setViewMode((prev) => (prev === "card" ? "table" : "card"))}
>
  {viewMode === "card" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-logs"
    >
      <path d="M13 12h8" />
      <path d="M13 18h8" />
      <path d="M13 6h8" />
      <path d="M3 12h1" />
      <path d="M3 18h1" />
      <path d="M3 6h1" />
      <path d="M8 12h1" />
      <path d="M8 18h1" />
      <path d="M8 6h1" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-columns-3"
    >
      <rect width="18" height="18" x="3" y="3" rx="2" />
      <path d="M9 3v18" />
      <path d="M15 3v18" />
    </svg>
  )}
</button>
      </div>

      {results.length === 0 ? (
        <div className="alert alert-info text-center">
          Keine Dokumente gefunden.
        </div>
      ) : (
        <>
          {viewMode === "card" ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {currentResults.map((doc) => (
                <div
                  key={doc.document_id}
                  className="card shadow-md hover:shadow-lg transition cursor-pointer"
                  onClick={() => handleShowModal(doc)}
                >
                <figure className="bg-gray-100 aspect-[1/1.414] relative">
  {loadingScreenshots[doc.document_uuid] ? (
    <div className="flex items-center justify-center h-full">
      <div className="spinner"></div>
    </div>
  ) : screenshotData[doc.document_uuid] ? (
    <DocumentPreviewWithZoom
      imageUrl={screenshotData[doc.document_uuid]}
    />
  ) : (
    <div className="flex items-center justify-center h-full text-gray-500">
      Keine Vorschau verfügbar
    </div>
  )}
</figure>
                  <div className="card-body">
                
                  <div class="inline-flex">
                  <button
    class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
    onClick={(event) => {
      event.stopPropagation(); // Verhindert ungewollte Klicks
      handleShowModal(doc); // Öffnet das Modal
    }}
  >
    Öffnen
  </button>

  <button
    class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
    onClick={(event) => {
      event.stopPropagation(); // Verhindert ungewollte Klicks
      downloadPdf(doc.document_uuid, event); // Ruft die PDF-Download-Funktion auf
    }}
  >
    <LucideIconWrapper name="Download" />
  </button>
</div>
                  <p className="flex items-center gap-2">
  <LucideIconWrapper name="Calendar" />
  {new Date(doc.document_date).toLocaleDateString()}
</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Dokument-ID</th>
                <th>Inhalt</th>
                <th>Tags</th>
                <th>Kommentare</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
  {currentResults.map((doc) => (
    <tr
      key={doc.document_id}
      onClick={() => handleShowModal(doc)} // Modal öffnen beim Klicken auf die Zeile
      className="cursor-pointer hover:bg-base-100" // Zeilen hervorheben beim Hover
    >
      <td>{new Date(doc.document_date).toLocaleDateString()}</td>
      <td>{doc.document_id}</td>
      <td>
        <div
          style={{
            maxHeight: "3rem",
            overflowY: "auto",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          className="p-2"
        >
          {doc.document_content
            ? cleanTsvector(doc.document_content)
            : "Kein Inhalt verfügbar"}
        </div>
      </td>
      <td>
        {doc.document_add_tag
          ? doc.document_add_tag.split(",").map((tag, index) => (
              <span key={index} className="badge badge-info badge-sm mx-1">
                {tag.trim()}
              </span>
            ))
          : "Keine Tags"}
      </td>
      <td>
        {doc.comments && doc.comments.length > 0 ? (
          <>
            <p>
              <strong>Letzter Kommentar:</strong>{" "}
              {doc.comments[doc.comments.length - 1]}
            </p>
          </>
        ) : (
          "Keine Kommentare"
        )}
      </td>
      <td>
        <div className="flex gap-2">
          <button
            className="btn btn-primary btn-sm"
            onClick={(event) => {
              event.stopPropagation(); // Verhindert, dass der Zeilen-Click auch das Modal öffnet
              handleShowModal(doc);
            }}
          >
            Anzeigen
          </button>
          <button
  className="btn btn-secondary btn-sm"
  onClick={(event) => {
    event.stopPropagation(); // Verhindert Modal-Öffnung
    downloadPdf(doc.document_uuid, event);
  }}
>
  PDF herunterladen
</button>
        </div>
      </td>
    </tr>
  ))}
</tbody>
          </table>
          )}
          <div className="flex justify-center mt-6 mb-6">
            <div className="btn-group">
              {Array.from({
                length: Math.ceil(results.length / resultsPerPage),
              }).map((_, index) => (
                <button
                  key={index + 1}
                  className={`btn ${
                    index + 1 === currentPage ? "btn-active" : ""
                  }`}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </>
      )}

      {/* Modal für Details */}
{/* Vollbild-Modal */}
{showModal && selectedDocument && (
  <>
    {/* Overlay */}
    <div className="modal modal-open">
      <div
        className="modal-box w-full max-w-none h-full bg-base-100 text-base-content animate-slideDown resultfull"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Modal Header */}
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <h3 className="font-bold text-lg">
              Doc-Id: {selectedDocument.document_id}
            </h3>
            <button
              className="btn btn-sm btn-circle btn-error"
              onClick={handleCloseModal}
            >
              ✕
            </button>
          </div>
        </div>
        <div className="divider"></div>

        {/* Modal Content */}
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 pr-8">
              <div className="p-4 bg-base-200 rounded shadow">
                <p>
                  <strong>Inhalt:</strong>{" "}
                  {cleanTsvector(selectedDocument.document_content)}
                </p>
                <p>
                  <strong>Archiv:</strong>{" "}
                  {selectedDocument.document_archive_id || "Nicht verfügbar"}
                </p>
              </div>

              <div className="p-4 bg-base-200 rounded shadow mt-6">
                <EditableTags
                  documentId={selectedDocument.document_id}
                  initialTags={
                    selectedDocument.document_add_tag
                      ?.split(",")
                      .map((tag) => tag.trim()) || []
                  }
                  onUpdate={(updatedTags) => {
                    const updatedDocument = {
                      ...selectedDocument,
                      document_add_tag: updatedTags.join(", "),
                    };
                    setSelectedDocument(updatedDocument);
                  }}
                />
              </div>

              <div className="p-4 bg-base-200 rounded shadow mt-6">
                <Comments documentId={selectedDocument.document_id} />
              </div>
            </div>

            <div className="w-full lg:w-1/2">
              <PDFViewer
                pdfUrl={
                  selectedDocument.document_uuid
                    ? `${process.env.REACT_APP_API_BASE_URL}/files/${selectedDocument.document_uuid}/${selectedDocument.document_uuid}.pdf`
                    : "#"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)}
      {/* Lade-Modal */}
      {isLoadingPdf && (
  <div className="modal modal-open">
    <div className="modal-box flex flex-col items-center justify-center text-center">
      <div className="spinner mb-4"></div>
      <p className="text-lg font-semibold">{loadingModalText}</p>
    </div>
  </div>
)}
    </div>
  );
};

export default ResultsPage;