import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import axiosInstance from "../api/axiosInstance";

const Comments = ({ documentId }) => {
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]); // Benutzerliste
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // Aktuell angemeldeter Benutzer

  // Benutzerinformationen abrufen
  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get("/users");
      if (Array.isArray(response.data)) {
        setUsers(response.data); // Benutzerliste speichern
      } else {
        console.warn("⚠️ API hat keine Array-Daten für Benutzer zurückgegeben:", response.data);
      }
    } catch (error) {
      console.error("❌ Fehler beim Abrufen der Benutzer:", error);
    }
  };

  // Aktuell angemeldeten Benutzer abrufen
  const fetchCurrentUser = async () => {
    try {
      const response = await axiosInstance.get("/auth/me"); // Endpunkt für aktuellen Benutzer
      setCurrentUser(response.data); // Aktuellen Benutzer speichern
    } catch (error) {
      console.error("❌ Fehler beim Abrufen des aktuellen Benutzers:", error);
    }
  };

  // Kommentare abrufen
  const fetchComments = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/comments?documentId=${documentId}`);
      if (Array.isArray(response.data)) {
        setComments(response.data); // Kommentare speichern
      } else {
        console.warn("⚠️ API hat keine Array-Daten für Kommentare zurückgegeben:", response.data);
      }
    } catch (error) {
      console.error("❌ Fehler beim Laden der Kommentare:", error);
    } finally {
      setLoading(false);
    }
  };

  // Neuen Kommentar hinzufügen
  const addComment = async () => {
    if (!newComment.trim()) return;

    try {
      const response = await axiosInstance.post("/comments", {
        documentId,
        text: newComment,
      });

      setComments([...comments, response.data]); // Neuen Kommentar hinzufügen
      setNewComment(""); // Eingabefeld zurücksetzen
    } catch (error) {
      console.error("❌ Fehler beim Hinzufügen des Kommentars:", error);
    }
  };

  // Kommentar löschen
  const deleteComment = async (commentId) => {
    try {
      await axiosInstance.delete(`/comments/${commentId}`);
      setComments(comments.filter((comment) => comment.comments_id !== commentId)); // Kommentar entfernen
    } catch (error) {
      console.error("❌ Fehler beim Löschen des Kommentars:", error);
    }
  };

  // Kombiniere Kommentare mit Benutzerinformationen
  const getUserName = (userId) => {
    const user = users.find((u) => u.user_id === userId);
    return user ? `${user.user_first_name} ${user.user_last_name}` : "Unbekannter Benutzer";
  };

  // Kommentare und Benutzerinformationen laden
  useEffect(() => {
    fetchUsers();
    fetchCurrentUser();
    fetchComments();
  }, [documentId]);

  return (
    <div>
      <h5>Kommentare</h5>
      {loading ? (
        <p>Kommentare werden geladen...</p>
      ) : (
        <div className="chat-container">
          {comments.length > 0 ? (
            comments.map((comment) => (
              <div key={comment.comments_id} className="chat chat-start">
                <div className="chat-header">
                  {getUserName(comment.user_id)} {/* Benutzername anzeigen */}
                  <time className="text-xs opacity-50">
                    {new Date(comment.comments_date).toLocaleString() || "Unbekannt"}
                  </time>
                </div>
                <div className="chat-bubble">{comment.comments_text}</div>
                {/* "Löschen"-Button nur anzeigen, wenn der Kommentar vom aktuellen Benutzer stammt */}
                {currentUser && currentUser.user_id === comment.user_id && (
                  <div
                    className="chat-footer opacity-50"
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => deleteComment(comment.comments_id)}
                  >
                    Löschen
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>Keine Kommentare vorhanden.</p>
          )}
        </div>
      )}
      <Form>
        <Form.Group>
          <Form.Control
            type="text"
            className="input input-bordered w-full max-w-xs"
            placeholder="Einen Kommentar hinzufügen..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
        </Form.Group>
        <Button className="btn btn-success ms-2" onClick={addComment}>
          Hinzufügen
        </Button>
      </Form>
    </div>
  );
};

export default Comments;