// Datei: src/components/ChangePassword.js

import React, { useState } from "react";
import axiosInstance from "../api/axiosInstance";
import { Form, Button, Alert } from "react-bootstrap";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put("/users/change-password", {
        currentPassword,
        newPassword,
      });

      setAlert({ show: true, message: response.data.message, variant: "success" });
      setCurrentPassword("");
      setNewPassword("");
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Fehler beim Ändern des Passworts.";
      setAlert({ show: true, message: errorMessage, variant: "danger" });
    }
  };

  return (
    <div className="container mt-5">
      <h1>Passwort ändern</h1>

      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Aktuelles Passwort</Form.Label>
          <Form.Control
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Neues Passwort</Form.Label>
          <Form.Control
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Passwort ändern
        </Button>
      </Form>
    </div>
  );
};

export default ChangePassword;