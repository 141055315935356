import React from "react";
import { Link, useNavigate } from "react-router-dom";
import LucideIconWrapper from "./LucideIconWrapper";
import { useAuth } from "../context/AuthContext";
import { useTheme } from "../context/ThemeContext"; // Importiere ThemeContext

const SidebarNav = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme(); // Zugriff auf Theme und toggleTheme

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div
      className="h-[calc(100vh-20px)] borderradius w-[60px] bg-gray-900 flex flex-col items-center text-white"
      style={{ zIndex: "2" }}
    >
      {/* Logo Section */}
      <div className="h-[90px] w-full flex items-center justify-center">
        <Link to="/search" className="text-xl font-bold text-primary">
          <img
            src="/images/anilogo-short.svg"
            alt="Tidion Logo"
            className="w-12 h-12"
          />
        </Link>
      </div>

      {/* Navigation Links */}
      <nav className="flex-1 flex flex-col items-center justify-center space-y-6">
        <a className="tooltip" data-tip="Upload">
          <LucideIconWrapper name="FileUp" />
        </a>
        <a className="tooltip" data-tip="Postkorb">
          <LucideIconWrapper name="CircuitBoard" />
        </a>
        <a className="tooltip" data-tip="Akten">
          <LucideIconWrapper name="Folder" />
        </a>
        <a className="tooltip" data-tip="Trennseite">
          <LucideIconWrapper name="Files" />
        </a>
        <Link to="/settings" className="tooltip" data-tip="Optionen">
          <LucideIconWrapper name="Settings" />
        </Link>
      </nav>

      {/* Dark Mode Switch */}
      <div className="mb-4">
        <div
          className="flex items-center cursor-pointer space-x-2"
          onClick={toggleTheme}
        >
          {/* Toggle Background */}
          <div className="w-10 h-6 bg-base-300 rounded-full flex items-center p-1">
            {/* Toggle Circle */}
            <div
              className={`w-5 h-5 rounded-full transform transition-transform ${
                theme === "dark" ? "translate-x-6 bg-yellow-400" : "translate-x-0 bg-gray-500"
              }`}
            ></div>
          </div>
          {/* Icons for Light and Dark */}
       
        </div>
      </div>

      {/* Logout Button */}
      <div className="mb-4">
        <button
          className="w-10 h-10 flex items-center justify-center rounded-lg hover:bg-gray-300"
          onClick={handleLogout}
        >
          <LucideIconWrapper name="LogOut" />
        </button>
      </div>
    </div>
  );
};

export default SidebarNav;