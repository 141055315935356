import React, { createContext, useContext, useState, useEffect } from "react";

// ThemeContext erstellen
const ThemeContext = createContext();

// Hook für einfachen Zugriff
export const useTheme = () => useContext(ThemeContext);

// ThemeProvider-Komponente
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "dark" // Standardmäßig Dark Mode
  );

  // Setze das Theme auf das `data-theme` Attribut
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme); // Speichere Theme in LocalStorage
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};