import React, { useState, useEffect } from "react";
import axiosInstance from "../api/axiosInstance";
import { useTitle } from "../context/TitleContext";

const SettingsPage = () => {
  const [userData, setUserData] = useState({
    user_2fa_enabled: false,
    "2fa_location": "",
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });

  const [show2FAModal, setShow2FAModal] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [location, setLocation] = useState("");
  const [token, setToken] = useState("");

  const [activeTab, setActiveTab] = useState("password"); // Tab-Auswahl
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Einstellungen"); // Setze den Titel
  }, [setTitle]);

  // Benutzerprofil abrufen
  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get("/auth/me");
      setUserData(response.data);
    } catch (error) {
      console.error("Fehler beim Abrufen der Benutzerdaten:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // Passwort ändern
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put("/users/change-password", {
        currentPassword,
        newPassword,
      });
      setAlert({ show: true, message: response.data.message, variant: "success" });
      setCurrentPassword("");
      setNewPassword("");
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Fehler beim Ändern des Passworts.";
      setAlert({ show: true, message: errorMessage, variant: "danger" });
    }
  };

  // 2FA Einrichten (QR-Code anfordern)
  const handle2FASetup = async () => {
    try {
      const response = await axiosInstance.post("/users/setup-2fa", { location });
      if (response.data.qrCode) {
        setQrCode(response.data.qrCode);
        setAlert({ show: true, message: "QR-Code generiert. Scannen Sie ihn mit Ihrer Authenticator-App.", variant: "success" });
        setShow2FAModal(true);
      }
    } catch (error) {
      console.error("Fehler beim Einrichten der 2FA:", error);
      const errorMessage =
        error.response?.data?.error || "Fehler beim Einrichten der 2FA.";
      setAlert({ show: true, message: errorMessage, variant: "danger" });
    }
  };

  // 2FA Bestätigen (Token verifizieren)
  const handle2FAConfirm = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/users/setup-2fa", { token, location });
      if (response.data.message) {
        setAlert({ show: true, message: response.data.message, variant: "success" });
        setShow2FAModal(false);
        setQrCode("");
        setToken("");
        fetchUserData();
      }
    } catch (error) {
      console.error("Fehler beim Bestätigen der 2FA:", error);
      const errorMessage =
        error.response?.data?.error || "Fehler beim Bestätigen der 2FA.";
      setAlert({ show: true, message: errorMessage, variant: "danger" });
    }
  };

  // 2FA Deaktivieren
  const handle2FADisable = async () => {
    try {
      const response = await axiosInstance.post("/users/disable-2fa");
      if (response.data.message) {
        setAlert({ show: true, message: response.data.message, variant: "success" });
        fetchUserData();
      }
    } catch (error) {
      console.error("Fehler beim Deaktivieren der 2FA:", error);
      const errorMessage =
        error.response?.data?.error || "Fehler beim Deaktivieren der 2FA.";
      setAlert({ show: true, message: errorMessage, variant: "danger" });
    }
  };

  return (
    <div className="container mx-auto mt-10 grid grid-cols-1 lg:grid-cols-3 gap-8">
      {/* Profilinformationen */}
      <div className="col-span-1 p-4 bg-base-200 text-base-content rounded shadow">
        <h4 className="text-xl font-semibold mb-4">Profilinformationen</h4>
        <p><strong>Vorname:</strong> {userData.user_first_name}</p>
        <p><strong>Nachname:</strong> {userData.user_last_name}</p>
        <p><strong>Loginname:</strong> {userData.user_loginname}</p>
      </div>

      {/* Tabs mit Einstellungen */}
      <div className="col-span-2 p-4 bg-base-100 text-base-content rounded shadow">
        <div className="tabs mb-4">
          <button
            className={`tab tab-bordered ${
              activeTab === "password" ? "tab-active bg-base-300 text-base-content" : ""
            }`}
            onClick={() => setActiveTab("password")}
          >
            Passwort ändern
          </button>
          <button
            className={`tab tab-bordered ${
              activeTab === "2fa" ? "tab-active bg-base-300 text-base-content" : ""
            }`}
            onClick={() => setActiveTab("2fa")}
          >
            Zwei-Faktor-Authentifizierung
          </button>
        </div>

        {/* Passwort ändern */}
        {activeTab === "password" && (
          <div>
            <h4 className="text-xl font-semibold mb-4">Passwort ändern</h4>
            {alert.show && (
              <div className={`alert ${
                alert.variant === "success" ? "alert-success" : "alert-error"
              } mb-4`}>
                {alert.message}
              </div>
            )}
            <form onSubmit={handlePasswordChange} className="space-y-4">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Aktuelles Passwort</span>
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="input input-bordered bg-base-100 text-base-content"
                  required
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Neues Passwort</span>
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="input input-bordered bg-base-100 text-base-content"
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">Passwort ändern</button>
            </form>
          </div>
        )}

        {/* Zwei-Faktor-Authentifizierung */}
        {activeTab === "2fa" && (
          <div>
            <h4 className="text-xl font-semibold mb-4">Zwei-Faktor-Authentifizierung (2FA)</h4>
            <p><strong>Status:</strong>{" "}
              {userData.user_2fa_enabled ? (
                <span className="badge badge-success">Aktiviert</span>
              ) : (
                <span className="badge badge-error">Deaktiviert</span>
              )}
            </p>
            <div className="form-control mt-4">
              <label className="label">
                <span className="label-text">Gerätebezeichnung</span>
              </label>
              {userData.user_2fa_enabled ? (
                <p>{userData["2fa_location"] || "Kein Ort angegeben"}</p>
              ) : (
                <input
                  type="text"
                  value={location || userData["2fa_location"] || ""}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="z.B. Handy, Laptop"
                  className="input input-bordered bg-base-100 text-base-content"
                />
              )}
            </div>
            <div className="mt-4">
              {userData.user_2fa_enabled ? (
                <button onClick={handle2FADisable} className="btn btn-error">2FA deaktivieren</button>
              ) : (
                <button onClick={handle2FASetup} className="btn btn-primary">2FA einrichten</button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;