import React, { useState, useEffect } from "react";
import axiosInstance from "../api/axiosInstance"; // Deine axiosInstance

const AdminPage = () => {
  const [users, setUsers] = useState([]); // Benutzerliste
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" }); // Alerts für Rückmeldungen
  const [showModal, setShowModal] = useState(false); // Modal für Erstellen/Bearbeiten
  const [formData, setFormData] = useState({}); // Formulardaten
  const [editing, setEditing] = useState(false); // Status: Bearbeiten oder Erstellen

  // Benutzerliste abrufen
  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get("/users");
      if (Array.isArray(response.data)) {
        setUsers(response.data); // Benutzerliste setzen
      } else {
        console.error("API returned invalid data:", response.data);
        showAlert("Fehler: Unerwartete Antwort von der API.", "error");
        setUsers([]); // Leere Liste, falls die API unerwartete Daten zurückgibt
      }
    } catch (error) {
      console.error("Fehler beim Abrufen der Benutzer:", error);
      showAlert("Fehler beim Abrufen der Benutzer.", "error");
    }
  };

  useEffect(() => {
    fetchUsers(); // Benutzer abrufen, wenn die Komponente geladen wird
  }, []);

  // Benutzer speichern (Erstellen oder Bearbeiten)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editing) {
        // Benutzer bearbeiten
        await axiosInstance.put(`/users/${formData.user_id}`, formData);
        showAlert("Benutzer erfolgreich aktualisiert!", "success");
      } else {
        // Neuen Benutzer erstellen
        await axiosInstance.post("/users", formData);
        showAlert("Benutzer erfolgreich erstellt!", "success");
      }
      fetchUsers(); // Liste aktualisieren
      resetForm(); // Formular zurücksetzen
      setShowModal(false); // Modal schließen
    } catch (error) {
      console.error("Fehler beim Speichern des Benutzers:", error);
      showAlert("Fehler beim Speichern des Benutzers.", "error");
    }
  };

  // Benutzer löschen
  const handleDelete = async (id) => {
    if (window.confirm("Möchten Sie diesen Benutzer wirklich löschen?")) {
      try {
        await axiosInstance.delete(`/users/${id}`);
        showAlert("Benutzer erfolgreich gelöscht!", "success");
        fetchUsers(); // Liste aktualisieren
      } catch (error) {
        console.error("Fehler beim Löschen des Benutzers:", error);
        showAlert("Fehler beim Löschen des Benutzers.", "error");
      }
    }
  };

  // Modal für Bearbeiten öffnen
  const handleEdit = (user) => {
    setFormData(user);
    setEditing(true);
    setShowModal(true);
  };

  // Modal für Erstellen öffnen
  const handleCreate = () => {
    resetForm();
    setEditing(false);
    setShowModal(true);
  };

  // Formular zurücksetzen
  const resetForm = () => {
    setFormData({
      user_first_name: "",
      user_last_name: "",
      user_loginname: "",
      user_password: "",
      user_is_admin: false,
      user_can_archive_1: false,
      user_can_archive_2: false,
      user_can_archive_3: false,
      user_can_archive_4: false,
      user_can_delete_1: false,
      user_can_delete_2: false,
      user_can_delete_3: false,
      user_can_delete_4: false,
    });
  };

  // Alert anzeigen
  const showAlert = (message, variant) => {
    setAlert({ show: true, message, variant });
    setTimeout(() => setAlert({ show: false, message: "", variant: "" }), 5000);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin-Seite</h1>

      {/* Alerts */}
      {alert.show && (
        <div
          className={`alert ${
            alert.variant === "success"
              ? "alert-success"
              : alert.variant === "error"
              ? "alert-error"
              : ""
          } mb-4`}
        >
          {alert.message}
        </div>
      )}

      <button
        onClick={handleCreate}
        className="btn btn-primary mb-4"
      >
        Neuen Benutzer erstellen
      </button>

      {/* Benutzerliste */}
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>ID</th>
              <th>Vorname</th>
              <th>Nachname</th>
              <th>Loginname</th>
              <th>Admin</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user) => (
                <tr key={user.user_id}>
                  <td>{user.user_id}</td>
                  <td>{user.user_first_name}</td>
                  <td>{user.user_last_name}</td>
                  <td>{user.user_loginname}</td>
                  <td>{user.user_is_admin ? "Ja" : "Nein"}</td>
                  <td>
                    <button
                      className="btn btn-warning mr-2"
                      onClick={() => handleEdit(user)}
                    >
                      Bearbeiten
                    </button>
                    <button
                      className="btn btn-error"
                      onClick={() => handleDelete(user.user_id)}
                    >
                      Löschen
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  Keine Benutzer gefunden.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal für Benutzerformular */}
      {showModal && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h3 className="font-bold text-lg">
              {editing ? "Benutzer bearbeiten" : "Neuen Benutzer erstellen"}
            </h3>
            <form onSubmit={handleSubmit} className="mt-4">
              <div className="form-control mb-4">
                <label className="label">Vorname</label>
                <input
                  type="text"
                  className="input input-bordered"
                  value={formData.user_first_name || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, user_first_name: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-control mb-4">
                <label className="label">Nachname</label>
                <input
                  type="text"
                  className="input input-bordered"
                  value={formData.user_last_name || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, user_last_name: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-control mb-4">
                <label className="label">Loginname</label>
                <input
                  type="text"
                  className="input input-bordered"
                  value={formData.user_loginname || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, user_loginname: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-control mb-4">
                <label className="label">Passwort</label>
                <input
                  type="password"
                  className="input input-bordered"
                  value={formData.user_password || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, user_password: e.target.value })
                  }
                />
              </div>
              <div className="form-control mb-4">
                <label className="cursor-pointer label">
                  <span className="label-text">Adminrechte</span>
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={formData.user_is_admin || false}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        user_is_admin: e.target.checked,
                      })
                    }
                  />
                </label>
              </div>

              {/* Berechtigungen für Archive */}
              <div className="form-control mb-4">
                <label className="label">Archiv-Berechtigungen</label>
                <div className="overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Archiv</th>
                        <th>Lesen</th>
                        <th>Löschen</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[1, 2, 3, 4].map((archive) => (
                        <tr key={`archive-${archive}`}>
                          <td>Archiv {archive}</td>
                          <td>
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={
                                formData[`user_can_archive_${archive}`] || false
                              }
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [`user_can_archive_${archive}`]:
                                    e.target.checked,
                                })
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={
                                formData[`user_can_delete_${archive}`] || false
                              }
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [`user_can_delete_${archive}`]:
                                    e.target.checked,
                                })
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="modal-action">
                <button type="submit" className="btn btn-primary">
                  Speichern
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={() => setShowModal(false)}
                >
                  Abbrechen
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;