import React, { createContext, useContext, useState } from "react";

// TitleContext erstellen
const TitleContext = createContext();

// Custom Hook für einfacheren Zugriff auf den Context
export const useTitle = () => useContext(TitleContext);

// Provider-Komponente für den Context
export const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState(""); // Zustand für den Titel

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};