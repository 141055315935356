import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import axiosInstance from "../api/axiosInstance";

// Importiere die CSS-Dateien für das Default Layout
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PDFViewer = ({ pdfUrl }) => {
    const [pdfBlobUrl, setPdfBlobUrl] = useState(null); // Blob-URL der PDF
    const [error, setError] = useState(null); // Fehlerstatus

    // Default Layout Plugin instanziieren
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    // PDF-Datei von der API laden
    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await axiosInstance.get(pdfUrl, {
                    responseType: "blob", // Datei als Blob abrufen
                });

                // Erstelle eine lokale URL aus dem Blob
                const blob = new Blob([response.data], { type: "application/pdf" });
                const blobUrl = URL.createObjectURL(blob);

                setPdfBlobUrl(blobUrl); // Lokale URL setzen
                setError(null); // Fehler zurücksetzen
            } catch (error) {
                console.error("❌ Fehler beim Laden der PDF-Datei:", error);
                setError("Fehler beim Laden der PDF. Bitte versuchen Sie es später erneut.");
            }
        };

        fetchPdf();

        // Speicher freigeben, wenn die Komponente entfernt wird
        return () => {
            if (pdfBlobUrl) {
                URL.revokeObjectURL(pdfBlobUrl);
            }
        };
    }, [pdfUrl]);

    return (
        <div style={{ height: "calc(100vh - 150px)", border: "1px solid #ccc", display: "flex", alignItems: "center", justifyContent: "center" }}>
            {error ? (
                <p style={{ color: "red" }}>{error}</p> // Fehlernachricht anzeigen
            ) : pdfBlobUrl ? (
                <Worker workerUrl="/js/pdf.worker.min.js">
                    <Viewer
                        fileUrl={pdfBlobUrl} // Lokale Blob-URL verwenden
                        plugins={[defaultLayoutPluginInstance]} // Default Layout Plugin nutzen
                    />
                </Worker>
            ) : (
                <div className="spinner"></div>
            )}
        </div>
    );
};

export default PDFViewer;