import * as LucideIcons from "lucide-react";

const LucideIconWrapper = ({ name, ...props }) => {
  const Icon = LucideIcons[name];
  if (!Icon) {
    console.warn(`Icon "${name}" not found in Lucide icons`);
    return null;
  }
  return <Icon strokeWidth={1.5} {...props} />;
};

export default LucideIconWrapper;